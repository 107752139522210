<script>
import { ref } from "vue";
import { Thumbs } from "swiper";

import Layout from "../../layouts/main.vue";
import appConfig from "../../../app.config";
import PageHeader from "@/components/page-header";
import { VueEditor } from "vue3-editor";

import SwiperCore, { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import FieldsServices from "../../services/FieldsServices";

import FieldInspector from "./parts/field-inspector";
import FieldPricing from "./parts/field-pricing";

SwiperCore.use([Pagination, Navigation]);

export default {
  page: {
    title: "Field Details",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  setup() {
    const thumbsSwiper = ref(null);
    const setThumbsSwiper = (swiper) => {
      thumbsSwiper.value = swiper;
    };

    return {
      Thumbs,
      thumbsSwiper,
      setThumbsSwiper,
    };
  },
  async beforeRouteEnter({ params }) {
    let current_field = {};
    await FieldsServices.getById(params.id).then((data) => {
      current_field = data.field;
    });

    localStorage.setItem("@CURRENT_FIELD", JSON.stringify(current_field));
  },
  beforeMount() {
    this.current_field = JSON.parse(
      localStorage.getItem("@CURRENT_FIELD") || "[]"
    );
  },
  data() {
    return {
      fields: {
        key: "",
        value: "",
        editing: false,
      },
      current_field: {},
      title: "Détails du site",
      items: [
        {
          text: "Sites",
          href: "/",
        },
        {
          text: "Détails du site",
          active: true,
        },
      ],
      productDetailsWidgets: [
        {
          id: 1,
          icon: "ri-money-dollar-circle-fill",
          label: "Nombre de lots",
          field: "lands_count",
        },
        {
          id: 2,
          icon: "ri-file-copy-2-fill",
          label: "Lots réservés",
          field: "picked_lands",
        },
        {
          id: 3,
          icon: "ri-stack-fill",
          label: "Souscriptions",
          field: "picked_lands",
        },
      ],
    };
  },
  methods: {
    startEditing(key) {
      this.fields.editing = true;
      this.fields.key = key;
    },
    async handlePerformUpdate() {
      this.$startLoading();
      await FieldsServices.updateProperty(this.current_field.id, {
        key: this.fields.key,
        value: this.fields.data,
      })
        .then((data) => {
          if (!data.error) {
            this.$saSuccess("Modification effectuée avec succès !");

            this.fields.editing = false;
            this.fields.key = "";
            this.fields.data = "";
          }
        })
        .catch(() => {
          this.$saError("Erreur lors de la modification.");
        })
        .finally(async () => {
          FieldsServices.getById(this.$route.params.id).then((data) => {
            this.current_field = data.field;
          });
        });
    },
  },
  components: {
    Layout,
    PageHeader,
    Swiper,
    SwiperSlide,
    FieldInspector,
    FieldPricing,
    VueEditor,
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row gx-lg-5">
              <div class="col-xl-4 col-md-8 mx-auto">
                <div
                  v-if="current_field.name"
                  class="product-img-slider sticky-side-div"
                >
                  <swiper
                    :modules="[Thumbs]"
                    class="product-thumbnail-slider p-2 rounded bg-light"
                    :navigation="{
                      nextEl: '.swiper-button-next bg-white shadow',
                      prevEl: '.swiper-button-prev bg-white shadow',
                    }"
                    :thumbs="{
                      swiper: thumbnailSwiper,
                    }"
                  >
                    <swiper-slide
                      v-for="image of current_field.large_pictures"
                      :key="image.id"
                    >
                      <img :src="image.large" class="img-fluid d-block" />
                    </swiper-slide>
                  </swiper>
                  <div class="swiper-button-next"></div>
                  <div class="swiper-button-prev"></div>
                  <!-- end swiper thumbnail slide -->
                  <swiper
                    :modules="[Thumbs]"
                    class="product-nav-slider mt-2"
                    :loop="false"
                    :spaceBetween="10"
                    :slidesPerView="4"
                    :freeMode="true"
                    watch-slides-progress
                    @swiper="setThumbsSwiper"
                  >
                    <swiper-slide
                      v-for="image of current_field.small_pictures"
                      :key="image.id"
                    >
                      <div class="nav-slide-item">
                        <img :src="image.small" alt class="img-fluid d-block" />
                      </div>
                    </swiper-slide>
                  </swiper>
                  <!-- end swiper nav slide -->
                </div>
              </div>
              <!-- end col -->

              <div class="col-xl-8">
                <div class="mt-xl-0 mt-5">
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      <h4>{{ current_field.name }}</h4>
                      <div class="hstack gap-3 flex-wrap">
                        <div class="vr"></div>
                        <div class="text-muted">
                          Publié le :
                          <span class="text-body fw-medium">{{
                            new Date(current_field.created_at).toLocaleString()
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="d-flex flex-wrap gap-2 align-items-center mt-3">
                    <div class="text-muted fs-16">
                      <span
                        v-for="i in 5"
                        :key="i"
                        class="mdi mdi-star"
                        :class="{
                          'text-warning': i <= current_field.ratings,
                        }"
                      ></span>
                    </div>
                    <div class="text-muted">
                      ( {{ current_field.reviews }} commentaires )
                    </div>
                  </div> -->

                  <div class="row mt-4">
                    <div
                      class="col-lg-4 col-sm-6"
                      v-for="(item, index) of productDetailsWidgets"
                      :key="index"
                    >
                      <div class="p-2 border border-dashed rounded">
                        <div class="d-flex align-items-center">
                          <div class="avatar-sm me-2">
                            <div
                              class="avatar-title rounded bg-transparent text-success fs-24"
                            >
                              <i :class="`${item.icon}`"></i>
                            </div>
                          </div>
                          <div class="flex-grow-1">
                            <p class="text-muted mb-1">{{ item.label }} :</p>
                            <h5 class="mb-0">
                              {{ current_field[item.field] || 0 }}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end col -->
                  </div>

                  <!-- <div class="mt-4 text-muted">
                    <h5 class="fs-14">Description :</h5>
                    <p>
                      {{ current_field.overview }}
                    </p>
                  </div> -->

                  <div class="product-content mt-5">
                    <h5 class="fs-14 mb-3">Spécifications sur le site :</h5>
                    <nav>
                      <ul
                        class="nav nav-tabs nav-tabs-custom nav-success"
                        id="nav-tab"
                        role="tablist"
                      >
                        <li class="nav-item">
                          <a
                            class="nav-link active"
                            id="nav-speci-tab"
                            data-bs-toggle="tab"
                            href="#nav-speci"
                            role="tab"
                            aria-controls="nav-speci"
                            aria-selected="true"
                          >
                            Spécifications
                          </a>
                        </li>
                        <!-- 'overview',
                          'advantages',
                          'potential_crops',
                        'future_plans',
                        'payment_terms', -->
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            id="overview-tab"
                            data-bs-toggle="tab"
                            href="#overview"
                            role="tab"
                            aria-controls="nav-speci"
                            aria-selected="true"
                          >
                            Présentation générale
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            id="advantages-tab"
                            data-bs-toggle="tab"
                            href="#advantages"
                            role="tab"
                            aria-controls="nav-speci"
                            aria-selected="true"
                          >
                            Atouts
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            id="potential_crops-tab"
                            data-bs-toggle="tab"
                            href="#potential_crops"
                            role="tab"
                            aria-controls="nav-speci"
                            aria-selected="true"
                          >
                            Cultures agricoles envisageables
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            id="future_plans-tab"
                            data-bs-toggle="tab"
                            href="#future_plans"
                            role="tab"
                            aria-controls="nav-speci"
                            aria-selected="true"
                          >
                            Projets à venir
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            id="payment_terms-tab"
                            data-bs-toggle="tab"
                            href="#payment_terms"
                            role="tab"
                            aria-controls="nav-speci"
                            aria-selected="true"
                          >
                            Modalités de paiement
                          </a>
                        </li>
                      </ul>
                    </nav>
                    <div
                      class="tab-content border border-top-0 p-4"
                      id="nav-tabContent"
                    >
                      <div
                        class="tab-pane fade show active"
                        id="nav-speci"
                        role="tabpanel"
                        aria-labelledby="nav-speci-tab"
                      >
                        <div class="table-responsive">
                          <table class="table mb-0">
                            <tbody>
                              <tr>
                                <th scope="row" style="width: 200px">
                                  Superficie initiale
                                </th>
                                <td>{{ current_field.initial_area }}</td>
                              </tr>
                              <tr>
                                <th scope="row" style="width: 200px">
                                  Superficie des routes
                                </th>
                                <td>{{ current_field.road_area }}</td>
                              </tr>
                              <tr>
                                <th scope="row" style="width: 200px">
                                  Superficie restante
                                </th>
                                <td>{{ current_field.remaining_area }}</td>
                              </tr>
                              <tr>
                                <th scope="row" style="width: 200px">
                                  Localisation
                                </th>
                                <td>
                                  {{ current_field.location.location }},
                                  {{ current_field.location.quater }},
                                  {{ current_field.location.subdivision }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div
                        class="tab-pane fade"
                        id="overview"
                        role="tabpanel"
                        aria-labelledby="nav-speci-tab"
                      >
                        <div>
                          <button
                            v-if="!fields.editing"
                            type="button"
                            class="mb-2 btn btn-primary"
                            @click="startEditing('overview')"
                          >
                            Modifier
                          </button>
                          <div v-html="current_field.overview"></div>
                        </div>
                        <div v-if="fields.key == 'overview' && fields.editing">
                          <VueEditor v-model="fields.data" />
                          <div class="mt-2">
                            <button
                              type="button"
                              class="btn btn-primary"
                              @click="handlePerformUpdate"
                            >
                              Enregistrer les modifications
                            </button>
                            <button
                              type="button"
                              class="btn btn-danger"
                              @click="fields.editing = false"
                            >
                              Annuler
                            </button>
                          </div>
                        </div>
                      </div>

                      <div
                        class="tab-pane fade"
                        id="advantages"
                        role="tabpanel"
                        aria-labelledby="nav-speci-tab"
                      >
                        <div>
                          <button
                            v-if="!fields.editing"
                            type="button"
                            class="mb-2 btn btn-primary"
                            @click="startEditing('advantages')"
                          >
                            Modifier
                          </button>
                          <div v-html="current_field.advantages"></div>
                        </div>
                        <div
                          v-if="fields.key == 'advantages' && fields.editing"
                        >
                          <VueEditor v-model="fields.data" />
                          <div class="mt-2">
                            <button
                              type="button"
                              class="btn btn-primary"
                              @click="handlePerformUpdate"
                            >
                              Enregistrer les modifications
                            </button>
                            <button
                              type="button"
                              class="btn btn-danger"
                              @click="fields.editing = false"
                            >
                              Annuler
                            </button>
                          </div>
                        </div>
                      </div>

                      <div
                        class="tab-pane fade"
                        id="potential_crops"
                        role="tabpanel"
                        aria-labelledby="nav-speci-tab"
                      >
                        <div>
                          <button
                            v-if="!fields.editing"
                            type="button"
                            class="mb-2 btn btn-primary"
                            @click="startEditing('potentialCrops')"
                          >
                            Modifier
                          </button>
                          <div v-html="current_field.potential_crops"></div>
                        </div>
                        <div
                          v-if="
                            fields.key == 'potential_crops' && fields.editing
                          "
                        >
                          <VueEditor v-model="fields.data" />
                          <div class="mt-2">
                            <button
                              type="button"
                              class="btn btn-primary"
                              @click="handlePerformUpdate"
                            >
                              Enregistrer les modifications
                            </button>
                            <button
                              type="button"
                              class="btn btn-danger"
                              @click="fields.editing = false"
                            >
                              Annuler
                            </button>
                          </div>
                        </div>
                      </div>

                      <div
                        class="tab-pane fade"
                        id="future_plans"
                        role="tabpanel"
                        aria-labelledby="nav-speci-tab"
                      >
                        <div>
                          <button
                            v-if="!fields.editing"
                            type="button"
                            class="mb-2 btn btn-primary"
                            @click="startEditing('futurePlans')"
                          >
                            Modifier
                          </button>
                          <div v-html="current_field.future_plans"></div>
                        </div>
                        <div
                          v-if="fields.key == 'future_plans' && fields.editing"
                        >
                          <VueEditor v-model="fields.data" />
                          <div class="mt-2">
                            <button
                              type="button"
                              class="btn btn-primary"
                              @click="handlePerformUpdate"
                            >
                              Enregistrer les modifications
                            </button>
                            <button
                              type="button"
                              class="btn btn-danger"
                              @click="fields.editing = false"
                            >
                              Annuler
                            </button>
                          </div>
                        </div>
                      </div>

                      <div
                        class="tab-pane fade"
                        id="payment_terms"
                        role="tabpanel"
                        aria-labelledby="nav-speci-tab"
                      >
                        <div>
                          <button
                            v-if="!fields.editing"
                            type="button"
                            class="mb-2 btn btn-primary"
                            @click="startEditing('paymentTerms')"
                          >
                            Modifier
                          </button>
                          <div v-html="current_field.payment_terms"></div>
                        </div>
                        <div
                          v-if="fields.key == 'payment_terms' && fields.editing"
                        >
                          <VueEditor v-model="fields.data" />
                          <div class="mt-2">
                            <button
                              type="button"
                              class="btn btn-primary"
                              @click="handlePerformUpdate"
                            >
                              Enregistrer les modifications
                            </button>
                            <button
                              type="button"
                              class="btn btn-danger"
                              @click="fields.editing = false"
                            >
                              Annuler
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- end card body -->
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>

        <div class="card">
          <div class="card-header bg-primary text-white">
            Informations sur les lots
          </div>
          <div class="card-body">
            <FieldInspector :field="current_field" />
          </div>
        </div>

        <div class="card">
          <div class="card-header bg-primary text-white">Gestion des packs</div>
          <div class="card-body">
            <FieldPricing :current-field="current_field" />
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
